import Vue from 'vue';
import App from './app';
import router from '@router';
import store from '@state/store';
import '@components/_globals';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

require('./bootstrap/axios.config');
require('./bootstrap/global-components');
require('./bootstrap/global-filters');
require('./bootstrap/global-styles');
require('./bootstrap/plugins');

Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [new Integrations.Vue({ Vue, attachProps: true })]
});

Vue.config.productionTip = process.env.NODE_ENV === 'production';

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
