<template>
    <div
        class="d-flex justify-content-start justify-content-md-end align-items-center flex-wrap"
    >
        <div>
            <div class="row">
                <div class="col-8 rows-per-page-label">
                    Rows per page:
                </div>
                <div class="col-4 per-page-awesome-table">
                    <b-form-select
                        v-model="rowsPerPage"
                        :options="[10, 25, 50, 100]"
                        :value="perPage"
                        @change="onPerPageChange"
                    />
                </div>
            </div>
        </div>
        <div class="ml-5 mr-2">
            <template v-if="total">
                {{ recordsFrom }}-{{ recordsTo }} of {{ total }}
            </template>
            <template v-else>
                0 of 0
            </template>
        </div>
        <div>
            <ul class="pagination pagination-rounded align-items-center pt-2">
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                    <span
                        class="page-link"
                        aria-label="Previous"
                        @click.prevent="setPage(currentPage - 1)"
                    >
                        <i class="fas fa-angle-left" />
                    </span>
                </li>
                <li
                    class="page-item"
                    :class="{ disabled: currentPage === lastPage }"
                >
                    <span
                        class="page-link"
                        aria-label="Next"
                        @click.prevent="setPage(currentPage + 1)"
                    >
                        <i class="fas fa-angle-right" />
                    </span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        currentPage: {
            type: Number,
            required: true
        },
        perPage: {
            type: Number,
            required: true
        },
        total: {
            type: Number,
            required: true
        }
    },

    data() {
        return {
            rowsPerPage: 10
        };
    },

    computed: {
        recordsFrom() {
            return this.currentPage * this.perPage - this.perPage + 1;
        },
        recordsTo() {
            let to = this.currentPage * this.perPage;

            if (to > this.total) {
                to = this.total;
            }

            return to;
        },
        lastPage() {
            return Math.ceil(this.total / this.perPage);
        }
    },

    watch: {
        perPage: {
            handler() {
                this.rowsPerPage = this.perPage;
            },
            immediate: true
        }
    },

    methods: {
        setPage(page) {
            if (page > 0 && page <= this.lastPage) {
                this.$emit('set-page', page);
            }
        },

        onPerPageChange() {
            this.$emit('set-per-page', this.rowsPerPage);
        }
    }
};
</script>
