import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async getContent(vuexContext, { key }) {
        const { data } = await axios.get(`/content/${key}`);

        return data;
    },

    async updateContent(vuexContext, { key, contentData }) {
        const { data } = await axios.put(`/content/${key}`, contentData);

        return data;
    }
};
