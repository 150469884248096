import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async index() {
        const { data } = await axios.get(`/label`);

        return data;
    },

    async store(vuexContext, labelData) {
        const { data } = await axios.post(`/label`, labelData);

        return data;
    },

    async update(vuexContext, labelData) {
        const { data } = await axios.put(`/label/${labelData.id}`, labelData);

        return data;
    },

    async destroy(vuexContext, labelData) {
        const { data } = await axios.delete(`/label/${labelData.id}`);

        return data;
    }
};
