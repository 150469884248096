<template>
    <div id="app">
        <layout v-if="layout === 'default'">
            <router-view :key="$route.fullPath" />
        </layout>
        <auth-layout v-if="layout === 'auth'">
            <router-view :key="$route.fullPath" />
        </auth-layout>
    </div>
</template>

<script>
import Layout from '@layouts/main';
import AuthLayout from '@layouts/auth';
import config from '@config';

export default {
    page: {
        // All subcomponent titles will be injected into this template.
        titleTemplate(title) {
            title = typeof title === 'function' ? title(this.$store) : title;
            return title ? `${title} | ${config.title}` : config.title;
        },

        meta: [{ name: 'description', content: config.description }]
    },

    components: {
        Layout,
        AuthLayout
    },

    data() {
        return {
            layout: 'default',
            authRoutes: ['login', 'forget-password', 'reset-password']
        };
    },

    watch: {
        '$route.fullPath'() {
            let route = this.$route.fullPath.split('/')[1];
            route = route.split('?')[0];

            if (this.authRoutes.includes(route)) {
                this.layout = 'auth';
            } else {
                this.layout = 'default';
            }
        }
    }
};
</script>

<!-- This should generally be the only global CSS in the app. -->
<style lang="scss">
@import '@design';
</style>
