import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async index(
        vuexContext,
        {
            currentPage = 1,
            perPage = 10,
            status = 'all',
            search = null,
            sortBy = null,
            descending = true,
            filters
        }
    ) {
        const { data } = await axios.get(`/events/`, {
            params: {
                page: currentPage,
                perPage,
                q: search,
                sortBy,
                order: descending ? 'DESC' : 'ASC',
                filters: JSON.stringify(filters),
                status
            }
        });

        return data;
    },

    async store(vuexContext, eventData) {
        const { data } = await axios.post(`/events`, eventData);

        return data;
    },

    async update(vuexContext, eventData) {
        const { data } = await axios.put(`/events/${eventData.id}`, eventData);

        return data;
    },

    async destroy(vuexContext, eventData) {
        const { data } = await axios.delete(`/events/${eventData.id}`);

        return data;
    },

    async archive(vuexContext, eventData) {
        const { data } = await axios.put(`/events/${eventData.id}/archive`);

        return data;
    },

    async csvExport(vuexContext, id) {
        const { data } = await axios.post(
            `/events/${id}/invited-users/csv-export`
        );

        return data;
    },

    async getInvitedUsers(
        vuexContext,
        {
            id,
            currentPage = 1,
            perPage = 10,
            status = 'all',
            search = null,
            sortBy = null,
            descending = true,
            filters
        }
    ) {
        const { data } = await axios.get(`/events/${id}/invited-users`, {
            params: {
                page: currentPage,
                perPage,
                q: search,
                sortBy,
                order: descending ? 'DESC' : 'ASC',
                filters: JSON.stringify(filters),
                status
            }
        });

        return data;
    }
};
