<template>
    <div class="filter-box">
        <i v-if="filterSelect" class="fas fa-filter">
            <div class="options">
                <v-select
                    v-model="type"
                    :options="types"
                    :clearable="false"
                    label="text"
                    @input="onTypeChange"
                />
            </div>
        </i>
        <input
            :value="value"
            :type="inputType"
            class="form-control"
            :placeholder="type.text"
            @input="onValueChange"
        />
        <i v-if="clearable && value" class="fas fa-trash" @click="clearValue" />
    </div>
</template>

<script>
export default {
    props: {
        default: {
            type: Object,
            required: false,
            default: null
        }
    },

    data() {
        return {
            value: '',
            type: {
                text: 'Contains',
                value: 'substring'
            },
            types: [
                {
                    text: 'Starts with',
                    value: 'startsWith'
                },
                {
                    text: 'Ends with',
                    value: 'endsWith'
                },
                {
                    text: 'Contains',
                    value: 'substring'
                }
            ],
            filterSelect: true,
            clearable: true,
            inputType: 'text',
            searchDelay: 500
        };
    },

    created() {
        if (this.default) {
            this.value = this.default.value;

            const type = this.types.find(
                item => item.value === this.default.type
            );

            this.type = { ...type };
        }
    },

    methods: {
        onFilterChange(value) {
            const filter = {
                value,
                type: this.type.value
            };

            this.value = value;

            this.$emit('filter-change', filter);
        },

        clearValue() {
            this.onFilterChange('');
        },

        onValueChange(event) {
            const { value } = event.target;

            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }

            this.timer = setTimeout(() => {
                this.onFilterChange(value);
            }, this.searchDelay);
        },

        onTypeChange() {
            this.onFilterChange(this.value);
        }
    }
};
</script>
