<template>
    <div class="columns-selector">
        <div class="btn show-columns-selector-button mr-2">
            <i class="fas fa-columns" />
        </div>
        <div class="labels-list card">
            <div class="card-body">
                <div v-for="(label, index) in labels" :key="index" class="row">
                    <div class="col-8 text-center py-1">
                        {{ getLabel(label) }}
                    </div>
                    <div class="col-4">
                        <input
                            type="checkbox"
                            :checked="!label.isHidden"
                            @click="onInputClick(index)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import getLabelHelper from './helpers/get-label';

export default {
    props: {
        labels: {
            type: Array,
            required: true
        }
    },

    methods: {
        getLabel(label) {
            return getLabelHelper(label);
        },

        onInputClick(index) {
            this.$emit('toggle-display', index);
        }
    }
};
</script>
