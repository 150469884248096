/* eslint-disable */
import axios from 'axios';

export const state = {
    currentUser: getSavedState('auth.currentUser')
};

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue;

        saveState('auth.currentUser', newValue);
        setDefaultAuthHeaders(state);
    }
};

export const getters = {
    loggedIn: state => !!state.currentUser,
    currentUser: state => state.currentUser
};

export const actions = {
    init({ state, dispatch }) {
        setDefaultAuthHeaders(state);
        dispatch('validate');
    },

    async login({ commit, dispatch, getters }, { email, password } = {}) {
        if (getters.loggedIn) {
            return dispatch('validate');
        }

        const { data: user } = await axios.post('/manager-auth/login', {
            email,
            password
        });

        if (user) {
            user.role = user.roles[0].name;
            commit('SET_CURRENT_USER', user);
        }

        return null;
    },

    async logout({ dispatch }) {
        await axios.post('/manager-auth/logout');
        dispatch('validate');
    },

    checkPasswordReset({ dispatch, getters }, { token }) {
        if (getters.loggedIn) {
            return dispatch('validate');
        }

        return axios.get(`/manager-auth/reset-password/${token}`);
    },

    sendResetPasswordLink({ dispatch, getters }, { email } = {}) {
        if (getters.loggedIn) {
            return dispatch('validate');
        }

        return axios.post(`/manager-auth/forgot-password`, { email });
    },

    resetPassword(
        { dispatch, getters },
        { password, password_confirmation, token } = {}
    ) {
        if (getters.loggedIn) {
            return dispatch('validate');
        }

        return axios.post(`/manager-auth/reset-password/${token}`, {
            password,
            password_confirmation
        });
    },

    async validate({ commit, state }) {
        if (!state.currentUser) {
            return null;
        }

        try {
            const { data: user } = await axios.get('/managers/me');

            user.role = user.roles[0].name;
            commit('SET_CURRENT_USER', user);

            return user;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                commit('SET_CURRENT_USER', null);
            }

            return null;
        }
    }
};

// ===
// Private helpers
// ===

function getSavedState(key) {
    return JSON.parse(window.localStorage.getItem(key));
}

function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state));
}

function setDefaultAuthHeaders(state) {
    axios.defaults.headers.common.Authorization = state.currentUser
        ? state.currentUser.token
        : '';
}
