import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async index(vuexContext, { currentPage = 1, perPage = 10 }) {
        const { data } = await axios.get(`/medical-informations/`, {
            params: {
                page: currentPage,
                perPage
            }
        });

        return data;
    },

    async store(vuexContext, medicalInformationData) {
        const { data } = await axios.post(
            `/medical-informations`,
            medicalInformationData
        );

        return data;
    },

    async update(vuexContext, medicalInformationData) {
        const { data } = await axios.put(
            `/medical-informations/${medicalInformationData.id}`,
            medicalInformationData
        );

        return data;
    },

    async destroy(vuexContext, medicalInformationData) {
        const { data } = await axios.delete(
            `/medical-informations/${medicalInformationData.id}`
        );

        return data;
    }
};
