import Vue from 'vue';
import Vuelidate from 'vuelidate';
import BootstrapVue from 'bootstrap-vue';

Vue.use(Vuelidate);
Vue.use(BootstrapVue);

Vue.prototype.$downloadFile = (href, name, blank = true) => {
    const fileLink = document.createElement('a');

    fileLink.href = href;
    fileLink.setAttribute('download', name);

    if (blank) {
        fileLink.setAttribute('target', '_blank');
    }

    fileLink.click();
};
