<template>
    <div class="btn btn-success mr-2" @click="copyToClipboard">
        <i class="far fa-copy" />
    </div>
</template>

<script>
import { url as appUrl } from '@config';

export default {
    props: {
        filters: {
            type: Object,
            required: false,
            default: null
        },
        pagination: {
            type: Object,
            required: false,
            default: null
        },
        suffix: {
            type: String,
            required: false,
            default: null
        }
    },

    computed: {
        url() {
            let url = `${appUrl}/${this.$route.path.slice(1)}?`;

            if (this.pagination) {
                const {
                    currentPage,
                    perPage,
                    search,
                    sortBy,
                    descending
                } = this.pagination;

                url += `page=${currentPage}&perPage=${perPage}&sortBy=${sortBy}&order=${
                    descending ? 'DESC' : 'ASC'
                }`;

                if (search) {
                    url += `&q=${search}`;
                }
            }

            if (this.filters && Object.keys(this.filters).length) {
                url += `&filters=${JSON.stringify(this.filters)}`;
            }

            if (this.suffix) {
                url += this.suffix;
            }

            return encodeURI(url);
        }
    },

    watch: {
        url: {
            handler() {
                history.pushState({}, '', this.url);
            },
            immediate: true
        }
    },

    methods: {
        copyToClipboard() {
            const el = document.createElement('textarea');
            el.value = this.url;

            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');

            document.body.removeChild(el);

            this.$bvToast.toast('Copied to clipboard!', {
                title: 'Success!',
                variant: 'success',
                solid: true
            });
        }
    }
};
</script>
