import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async index(
        vuexContext,
        {
            currentPage = 1,
            perPage = 10,
            isApproved = true,
            search = null,
            sortBy = null,
            descending = true,
            filters,
            attributes
        }
    ) {
        const { data } = await axios.get(`/users`, {
            params: {
                page: currentPage,
                perPage,
                q: search,
                sortBy,
                order: descending ? 'DESC' : 'ASC',
                filters: JSON.stringify(filters),
                isApproved,
                attributes
            }
        });

        return data;
    },

    async store(vuexContext, doctorData) {
        const { data } = await axios.post(`/users`, doctorData);

        return data;
    },

    async update(vuexContext, doctorData) {
        const { data } = await axios.put(`/users/${doctorData.id}`, doctorData);

        return data;
    },

    async destroy(vuexContext, doctorData) {
        const { data } = await axios.delete(`/users/${doctorData.id}`);

        return data;
    },

    async approve(vuexContext, doctorData) {
        const { data } = await axios.post(
            `/users/approve-user/${doctorData.id}`,
            doctorData
        );

        return data;
    },

    async addDoctors(vuexContext, file) {
        const { data } = await axios.post(`/users/add-many`, file);

        return data;
    },

    async csvExport() {
        const { data } = await axios.post('/login-items/csv-export');

        return data;
    },

    async getUserEvents(vuexContext, id) {
        const { data } = await axios.get(`/users/${id}/events`);

        return data.map(userEvent => userEvent.event);
    }
};
