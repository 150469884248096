import axios from 'axios';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.withCredentials = true;

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (!error.response) {
            console.error(error);
            window.location.href = '/login';
            localStorage.clear();
            return;
        }

        const err = error.response.request.responseURL;
        const fromUrl = err.split('/').pop();
        const nonRedirectUrls = ['login', 'forgot-password', 'reset-password'];

        console.warn('Error status', error.response.status);

        if (
            error.response.status === 401 &&
            !nonRedirectUrls.includes(fromUrl)
        ) {
            window.location.href = '/login';
            localStorage.clear();
        }

        throw error;
    }
);

export default axios;
