import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async getSettingByKey(vuexContext, { key }) {
        const { data } = await axios.get(`/settings/key/${key}`);

        return data;
    },

    async getSettings() {
        const { data } = await axios.get(`/settings`);

        return data;
    },

    async updateSettingsByTags(vuexContext, { tag, settings }) {
        const { data } = await axios.post('/settings', { tag, settings });

        return data;
    }
};
