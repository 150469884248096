import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async index() {
        const { data } = await axios.get(`/contact`);

        return data;
    },

    async store(vuexContext, contactData) {
        const { data } = await axios.post(`/contact`, contactData);

        return data;
    },

    async update(vuexContext, contactData) {
        const { data } = await axios.put(
            `/contact/${contactData.id}`,
            contactData
        );

        return data;
    },

    async destroy(vuexContext, contactData) {
        const { data } = await axios.delete(`/contact/${contactData.id}`);

        return data;
    },

    async getEmailGroups() {
        const { data } = await axios.get(`/contact/emails`);

        return data;
    },

    async addEmailGroup(vuexContext, contactData) {
        const { data } = await axios.post(`/contact/emails`, contactData);

        return data;
    },

    async updateEmailGroups(vuexContext, contactData) {
        const { data } = await axios.put(`/contact/emails/update`, contactData);

        return data;
    }
};
