import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async index() {
        const { data } = await axios.get(`/products/`);

        return data;
    },

    async show(vuexContext, id) {
        const { data } = await axios.get(`/products/${id}`);

        return data;
    },

    async update(vuexContext, { id, productData }) {
        const { data } = await axios.put(`/products/${id}`, productData);

        return data;
    },

    async addVideo(vuexContext, { productId, video }) {
        const { data } = await axios.post(
            `/products/${productId}/video`,
            video
        );

        return data;
    },

    async addPdf(vuexContext, { productId, pdf }) {
        const { data } = await axios.post(`/products/${productId}/pdf`, pdf);

        return data;
    },

    async deleteVideo(vuexContext, id) {
        const { data } = await axios.delete(`products/videos/${id}`);

        return data;
    },

    async deletePdf(vuexContext, id) {
        const { data } = await axios.delete(`products/pdfs/${id}`);

        return data;
    },

    async changeVideoOrder(vuexContext, { orderedVideos, productId }) {
        await axios.post(`/products/${productId}/video/order`, {
            items: orderedVideos
        });
    },

    async changePdfOrder(vuexContext, { orderedPdfs, productId }) {
        await axios.post(`/products/${productId}/pdf/order`, {
            items: orderedPdfs
        });
    }
};
