import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async index(
        vuexContext,
        {
            currentPage = 1,
            perPage = 10,
            search = null,
            sortBy = null,
            descending = true,
            filters
        }
    ) {
        const { data } = await axios.get('/csvs', {
            params: {
                page: currentPage,
                perPage,
                q: search,
                sortBy,
                order: descending ? 'DESC' : 'ASC',
                filters: JSON.stringify(filters)
            }
        });

        return data;
    },

    async download(vuexContext, id) {
        const { data } = await axios.get(`/csvs/${id}/download`);

        return data;
    },

    delete(vuexContext, id) {
        axios.delete(`/csvs/${id}`);
    }
};
